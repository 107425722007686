<template>
  <fieldset :disabled="disabled">
    <b-card no-body>
      <b-card-body>
        <b-form ref="toyForm" novalidate>
          <fieldset class="mb-3">
            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="validation.name"
                  />
                  <b-form-invalid-feedback v-if="$v.form.name.$invalid">
                    Informe o nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Código" label-for="code">
                  <b-form-input
                    id="code"
                    v-model="form.code"
                    :state="validation.code"
                  />
                  <b-form-invalid-feedback v-if="$v.form.code.$invalid">
                    Informe o código
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Imagem" label-for="image">
                  <ImageInput v-model="form.image" :validation="validation" />
                </b-form-group>
              </b-col>
            </b-form-row>

            <StoresFetcher>
              <template #default="{ data }">
                <fieldset>
                  <div lg="6" md="6" sm="12" class="flex-1">
                    <b-form-group label="Loja" label-for="store">
                      <BaseSelect
                        v-model="form.storeId"
                        :options="parseStoreOptions(data)"
                        :reduce="onReduceStore"
                        :clearable="false"
                        :validation="$v.form.storeId"
                      />
                      <b-form-invalid-feedback v-if="$v.form.storeId.$invalid">
                        Informe a loja
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </fieldset>
              </template>
            </StoresFetcher>

            <StatusInput
              v-model="form.status"
              :validation="validation.status"
              :invalid="$v.form.status.$invalid"
            />
          </fieldset>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button variant="outline-secondary" @click="handleCancel('ToysIndex')">
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit"> Salvar </b-button>
    </div>
  </fieldset>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import ImageInput from '@/components/ImageInput'
import BaseSelect from '@/components/BaseSelect'
import StoresFetcher from '@/components/Stores/StoresFetcher'
import StatusInput from '@/components/StatusInput'

export default {
  name: 'ToyForm',
  components: {
    BaseSelect,
    StoresFetcher,
    ImageInput,
    StatusInput
  },
  mixins: [
    withFormValidation({
      fields: ['name', 'code', 'image', 'status', 'storeId']
    })
  ],
  validations: {
    form: {
      storeId: { required },
      name: { required },
      code: { required },
      image: { required },
      status: { required }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    parseStoreOptions(stores) {
      return stores.map(({ id, name }) => ({
        code: id,
        label: name
      }))
    },

    onReduceStore({ code }) {
      return code
    }
  }
}
</script>
