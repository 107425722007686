<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <StateRenderer v-bind="toy">
      <ToyForm v-model="model" :disabled="isLoading" @submit="onSubmit" />
    </StateRenderer>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/constants'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import Layout from '@/views/Layout'
import ToyForm from '@/components/Toys/ToyForm.vue'
import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'ToyRegisterView',
  components: {
    Layout,
    ToyForm,
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'toy',
      fetcher: {
        methodName: 'fetchToyById',
        handler: services.toys.fetchToyById
      }
    }),
    withAsyncAction({
      key: 'createToyState',
      fetcher: {
        methodName: 'createToy',
        handler: services.toys.createToy
      }
    }),
    withAsyncAction({
      key: 'updateToyState',
      fetcher: {
        methodName: 'updateToy',
        handler: services.toys.updateToy
      }
    })
  ],
  data() {
    return {
      model: {
        name: '',
        storeId: null,
        code: '',
        image: null,
        status: STATUS.ACTIVE.value
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Brinquedos',
        to: { name: 'ToysIndex' }
      },
      {
        text: 'Cadastro',
        to: { name: 'ToyRegister' },
        active: true
      }
    ],
    isLoading() {
      return this.createToyState.loading || this.updateToyState.loading
    }
  },
  async created() {
    const { id } = this.$route.params

    if (id) {
      this.model = await this.fetchToyById(id)
    } else {
      this.model.storeId = this.currentStoreId
    }
  },
  methods: {
    async onSubmit(toy) {
      if (toy.id) {
        await this.updateToy(toy)
      } else {
        await this.createToy(toy)
      }

      this.$root.$toast.success('Brinquedo salvo com sucesso')
      this.$router.push({ name: 'ToysIndex' })
    }
  }
}
</script>
